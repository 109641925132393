
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























.media-list__item {
  border-top: 1px currentColor solid;
}

.media-list__dl-link {
  @include liquid('padding-top', 12px, 15px);
  @include liquid('padding-bottom', 12px, 15px);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.media-list__dl-link__size {
  margin-left: 2rem;
  padding: 0.7rem 1.2rem;
  text-transform: uppercase;
  border: 1px currentColor solid;
  border-radius: 6rem;
  white-space: nowrap;
}

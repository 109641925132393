
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































.bullet-list__item {
  text-decoration: none;
}

.bullet-list__item__title {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1.3;

  @include mq($from: m) {
    flex-direction: row;
  }
}

.bullet-list__item__date {
  margin-top: 0.25em;

  @include mq($from: m) {
    margin-top: 0;
    margin-left: auto;
  }
}
